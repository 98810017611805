import { ChangeEvent, FC, useState } from "react";
import { useDispatch } from "react-redux";
import dayjs, { Dayjs } from "dayjs";
import { useNavigate, useSearchParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { TableBody as Body, TableCell, TableRow } from "@mui/material";
import {
  CloseOutlined as Close,
  CheckOutlined as Check,
  EditOutlined as Edit,
  LockOpenOutlined as Lock,
  RemoveRedEyeOutlined as View,
  DownloadOutlined as Download,
  InfoOutlined as MoreInfo,
} from "@mui/icons-material";
import { Tooltip } from "@mui/material";

import {
  dateNormalizer,
  errorNormalizer,
} from "../../../shared/Helpers/functions";
import { HeaderProperties } from "../../../shared/uiComponents/Table/tableProperties";
import {
  CustomAlert,
  CustomButton,
  CustomCheckBox,
  Picker,
} from "../../../shared/uiComponents";
import { useCheckPermission } from "../../../shared/Helpers/hooks";
import { AdminTypes } from "../../../components/Menu";
import { ButtonWrapper } from "../../../components/StyledComponents";
import { Wrapper } from "./helpers";
import { PERMISSIONS } from "../../../App/constants";
import { Info } from "../User/tableInfo";

import { DispatchProperties, useSelector } from "../../../redux/store";
import {
  clearErrors,
  getSoapNotesAdmin,
  unlockSoapNote,
} from "../../../redux/State/clientSlice/soapNoteSlice";
import {
  GetSoapNoteProperties,
  SoapNotesFullProperties,
} from "../../../redux/API/ClientAPIHelpers/soapNoteProperties";
import {
  downloadSoapNotePDF,
  getSoapNotePDF,
} from "../../../redux/State/exportSlice";
import { SoapNotesStatuses } from "../../../redux/API/ClientAPIHelpers/dataCollectionProperties";

export interface RowRendererProperties {
  data: Array<SoapNotesFullProperties>;
}

export const Headers: Array<HeaderProperties> = [
  { id: "0", name: "Provider Name", orderBy: "user.fullName" },
  { id: "1", name: "Client Name", orderBy: "client.fullName" },
  { id: "2", name: "Type", orderBy: "sessionType.name" },
  { id: "3", name: "Session Date", orderBy: "date" },
  { id: "4", name: "SOAP Note Created", orderBy: "createdAt" },
  { id: "5", name: "Created by", orderBy: "createdBy.fullName" },
  {
    id: "6",
    name: "Status",
    titles: [
      "Draft",
      "Submitted",
      "Expired BT",
      "Expired BCBA",
      "Unlocked",
      "Pending BT",
      "Time/Location Conflict",
      "All",
    ], //All keyword is for reseting column
    width: "200px",
    titlesHeader: "Select Status:",
  },
  { id: "7", name: "Actions", width: "120px" },
];

export const TableBody: FC<RowRendererProperties> = ({ data }) => (
  <>
    <Body>
      {data.map((row, index) => (
        <TableRow key={index}>
          <TableCell>{row.user.fullName}</TableCell>
          <TableCell>{row.client.fullName}</TableCell>
          <TableCell>{row.sessionType.name}</TableCell>
          <TableCell>{dayjs(row.date).format("MM/DD/YYYY")}</TableCell>
          <TableCell>{dayjs(row.createdAt).format("MM/DD/YYYY")}</TableCell>
          <TableCell>{row.createdBy.fullName}</TableCell>
          <TableCell>{row.status.name}</TableCell>
          <TableCell>
            <SOAPNoteActions data={row} />
          </TableCell>
        </TableRow>
      ))}
    </Body>
  </>
);

export const SOAPNoteActions = ({
  data,
}: {
  data: SoapNotesFullProperties;
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<DispatchProperties>();

  const DOWNLOAD = useCheckPermission(PERMISSIONS.SOAPNOTE.PDF);
  const UNLOCK = useCheckPermission(PERMISSIONS.SOAPNOTE.UNLOCK);
  const EDIT = useCheckPermission(PERMISSIONS.SOAPNOTE.EDIT);

  const [includeTrials, setIncludeTrials] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [openInfo, setOpenInfo] = useState<boolean>(false);

  const loading = useSelector((state) => state.export.loading);
  const role = useSelector((state) => state.account.role);

  const { id, client, status, sessionType, sessionId, submittedAt } = data;

  const downloadSoapNoteHandler = () => {
    dispatch(
      downloadSoapNotePDF({
        reportId: id,
        includeTrials,
      })
    )
      .then(unwrapResult)
      .then((response) => {
        const objectURL = URL.createObjectURL(response);
        toast("Downloaded");
        const date = dayjs(submittedAt).format("MM-DD-YYYY-hh-mm-a");
        const link = document.createElement("a");
        link.href = objectURL;
        link.setAttribute("download", `${client.fullName}-${date}`);
        document.body.appendChild(link);
        link.click();
      })
      .catch(errorNormalizer)
      .finally(() => setOpen(false));
  };

  const viewSoapNoteHandler = () => {
    dispatch(
      getSoapNotePDF({
        reportId: id,
        includeTrials,
      })
    )
      .then(unwrapResult)
      .then((response) => {
        const objectURL = URL.createObjectURL(response);
        window.open(objectURL);
      })
      .catch(errorNormalizer);
  };

  const includeTrialsHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setIncludeTrials(checked);
  };

  const navigateSoapNoteHandler = () => {
    dispatch(clearErrors());
    const query = new URLSearchParams();
    query.set("type", `${sessionType.type}`);
    query.set("soapNoteStatus", `${status.status}`);
    query.set("clientName", client.fullName);
    query.set("reportId", id);
    query.set("sessionId", sessionId);
    query.set("clientId", client.id);
    navigate(`/soap-notes/edit?${query.toString()}`);
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "8px",
        cursor: "pointer",
      }}
    >
      <Tooltip title="More Information">
        <MoreInfo fontSize="small" onClick={() => setOpenInfo(true)} />
      </Tooltip>
      {(status.status === SoapNotesStatuses.expiredWeek ||
        status.status === SoapNotesStatuses.expired48) &&
        role?.section.id !== AdminTypes.bt && (
          <>
            {UNLOCK.permissionGranted && (
              <Unlock reportId={id} clientId={client.id} />
            )}
            <Tooltip title="View">
              <View fontSize="small" onClick={navigateSoapNoteHandler} />
            </Tooltip>
          </>
        )}
      {(status.status === SoapNotesStatuses.draft ||
        status.status === SoapNotesStatuses.unlocked) &&
        EDIT.permissionGranted && (
          <Tooltip title="Edit">
            <Edit fontSize="small" onClick={navigateSoapNoteHandler} />
          </Tooltip>
        )}
      {status.status === SoapNotesStatuses.submitted && (
        <>
          <Tooltip title="View">
            <View fontSize="small" onClick={viewSoapNoteHandler} />
          </Tooltip>
          {DOWNLOAD.permissionGranted && role?.section.id !== AdminTypes.bt && (
            <Tooltip title="Download PDF">
              <Download fontSize="small" onClick={() => setOpen(true)} />
            </Tooltip>
          )}
        </>
      )}
      {status.status === SoapNotesStatuses.pendingBT && (
        <>
          <Tooltip title="View">
            <View fontSize="small" onClick={viewSoapNoteHandler} />
          </Tooltip>
        </>
      )}
      {status.status === SoapNotesStatuses.timeMismatch && (
        <>
          <Tooltip title="View">
            <View fontSize="small" onClick={viewSoapNoteHandler} />
          </Tooltip>
          {EDIT.permissionGranted && (
            <Tooltip title="Edit">
              <Edit fontSize="small" onClick={navigateSoapNoteHandler} />
            </Tooltip>
          )}
        </>
      )}
      <CustomAlert
        title="Include Trials?"
        open={open}
        onClose={() => setOpen(false)}
        Content={() => (
          <>
            <CustomCheckBox
              item={{
                id: "1",
                checked: includeTrials,
                label: "Include",
              }}
              onChange={includeTrialsHandler}
            />
            <CustomButton
              title="Download PDF"
              onClick={downloadSoapNoteHandler}
              loading={loading}
            />
          </>
        )}
      />
      <CustomAlert
        open={openInfo}
        onClose={() => setOpenInfo(false)}
        title={"More Information"}
        Content={() => <Info note={data} />}
      />
    </div>
  );
};

const initialExpirationDate = dayjs().add(2, "days");

const Unlock = ({ clientId, reportId }: GetSoapNoteProperties) => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params] = useSearchParams();

  const [unlockConfirm, setUnlockConfirm] = useState<boolean>(false);
  const [addNextExpiration, setAddNextExpiration] = useState<boolean>(false);
  const [expirationDateValue, setExpirationDateValue] = useState<Dayjs | null>(
    initialExpirationDate
  );

  const unlockHandler = () => {
    setUnlockConfirm(false);
    if (!reportId || !clientId) return;
    const page = params.get("page") || "1";
    const pageSize = params.get("pageSize") || "8";

    dispatch(
      unlockSoapNote({
        reportId,
        clientId,
        nextExpirationDate: dateNormalizer(dayjs(expirationDateValue)),
      })
    )
      .then(unwrapResult)
      .then(() => {
        dispatch(getSoapNotesAdmin({ page, pageSize }));
        toast("Success");
      })
      .catch(errorNormalizer);
  };

  return (
    <Wrapper unlockConfirm={unlockConfirm}>
      <Tooltip title="Unlock">
        {unlockConfirm ? (
          <>
            <Close fontSize="small" onClick={() => setUnlockConfirm(false)} />
            <Check
              fontSize="small"
              onClick={() => setAddNextExpiration(true)}
            />
          </>
        ) : (
          <Lock fontSize="small" onClick={() => setUnlockConfirm(true)} />
        )}
      </Tooltip>
      <CustomAlert
        title="Set next expiration date"
        onClose={() => setAddNextExpiration(false)}
        open={addNextExpiration}
        Content={() => (
          <>
            <ButtonWrapper>
              <Picker.CustomDate
                label="Expiration date"
                value={expirationDateValue}
                onChange={setExpirationDateValue}
              />
            </ButtonWrapper>
            <ButtonWrapper>
              <CustomButton
                secondaryButton
                title="Cancel"
                onClick={() => setAddNextExpiration(false)}
              />
              <CustomButton title="Unlock" onClick={unlockHandler} />
            </ButtonWrapper>
          </>
        )}
      />
    </Wrapper>
  );
};
