import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../API/Main/API";
import { resetState } from "../../resetState";
import {
    InitialSoapNoteStateProperties,
    GetSoapNoteProperties,
    SoapNotesDataProperties,
    GetSoapNotesProperties,
    UpdateBillingCredentialedProperties,
    GetExcelReportProperties,
    GetPendingSoapNotesProperties,
    ApprovePendingSoapNotesProperties,
    ApprovePendingSoapNoteProperties,
    UnlockSoapNoteProperties,
} from "../../API/ClientAPIHelpers/soapNoteProperties";
import {
    SaveBehaviorTreatmentDataProperties,
    UpdateBehaviorTreatmentDataProperties,
} from "../../../pages/SOAPNote/SoapNoteProperties/behaviorTreatmentTypeProperties";
import {
    SaveDirectSupervisionDataProperties,
    UpdateDirectSupervisionDataProperties,
} from "../../../pages/SOAPNote/SoapNoteProperties/directSupervisionTypeProperties";
import {
    SaveAssessmentTypeDataProperties,
    UpdateAssessmentTypeDataProperties,
} from "../../../pages/SOAPNote/SoapNoteProperties/assessmentTypeProperties";
import {
    SaveBehaviorTreatmentModificationTypeDataProperties,
    UpdateBehaviorTreatmentModificationTypeDataProperties
} from "../../../pages/SOAPNote/SoapNoteProperties/behaviorTreatmentModificationTypeProperties";
import {
    SaveParentTrainingTypeDataProperties,
    UpdateParentTrainingTypeDataProperties
} from "../../../pages/SOAPNote/SoapNoteProperties/parentTrainingTypeProperties";
import {
    SaveTreatmentPlanningTypeDataProperties,
    UpdateTreatmentPlanningTypeDataProperties
} from "../../../pages/SOAPNote/SoapNoteProperties/treatmentPlanningTypeProperties";
import { ErrorProperties } from "../../API/identityAPIProperties";

export const initialSoapNoteState: InitialSoapNoteStateProperties = {
    loading: false,
    behaviorTreatmentOptions: {
        barrierToTreatmentOptions: [],
        behaviorTreatmentTechniqueOptions: [],
        promptLevelOptions: [],
        reinforcementOptions: [],
        behaviorTreatmentFuturePlanOptions: [],
        clientMoodOptions: [],
        clientResponseToTreatmentOptions: [],
        levelOfMaladaptiveBehaviorOptions: [],
        maladaptiveBehaviorTechniqueOptions: [],
        skillDomainAddressedOptions: [],
        behaviorTreatmentParticipantOptions: [],
    },
    directSupervisionOptions: {
        feedbackOptions: [],
        directSupervisionParticipantOptions: [],
    },
    assessmentOptions: {
        assessmentToolOptions: [],
        assessmentParticipantOptions: [],
        treatmentPlanOptions: [],
        assessmentActivityOptions: [],
    },
    behaviorTreatmentModificationOptions: {
        barrierToTreatmentOptions: [],
        behaviorTreatmentModificationInterventionOptions: [],
        reinforcementOptions: [],
        responseToInterventionOptions: [],
        behaviorTreatmentModificationParticipantOptions: [],
    },
    parentTrainingOptions: {
        directParentTrainingOptions: [],
        parentTrainingParticipantOptions: [],
    },
    treatmentPlanningOptions: {
        treatmentPlanningParticipantOptions: [],
        treatmentUpdateOptions: [],
    },
    soapNotes: {
        query: null,
        page: 1,
        totalNumberOfItems: 0,
        numberOfItems: 0,
        totalPages: 1,
        hasNextPage: false,
        hasPreviousPage: false,
    },
    dsuSoapNotes: {
        query: null,
        page: 1,
        totalNumberOfItems: 0,
        numberOfItems: 0,
        totalPages: 1,
        hasNextPage: false,
        hasPreviousPage: false,
    },
    pendingSoapNotes: {
        query: null,
        page: 1,
        totalNumberOfItems: 0,
        numberOfItems: 0,
        totalPages: 1,
        hasNextPage: false,
        hasPreviousPage: false,
    },
    soapNote: {},
    locations: null,
    telehealtLocations: [],
    error: {
        status: 0,
        title: "",
    },
    soapNoteData: {
        supervisor: {
            authoritationType: {
                id: 0,
                name: "",
            },
            fullName: "",
            id: "",
            role: "",
        }
    }
}

export const updateBillingCredentialed = createAsyncThunk(
    'soapNote/update/user/notes',
    async (data: UpdateBillingCredentialedProperties, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.updateBillingCredentialed(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response
    }
)

export const getTelehealthLocations = createAsyncThunk(
    'soapNote/telehealthlocations',
    async (_, thunkAPI) => {
        const response = await API.ClientAPI.TelehealthLocation.getTelehealthLocations()
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response
    }
)

export const getSoapNotesUser = createAsyncThunk(
    'soapNote/get/notes',
    async (data: GetSoapNotesProperties, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.getSoapNotesUser(data)
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as SoapNotesDataProperties
    }
)

export const getPendingDSUSoapNotes = createAsyncThunk(
    'soapNote/get/notes/pending-dsu',
    async (data: GetExcelReportProperties, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.getPendingDSUSoapNotes(data)
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as {
            file: Blob,
            fileName: string,
        };
    }
)

export const getExpiredSessions = createAsyncThunk(
    'soapNote/get/notes/expired-sessions',
    async (data: GetExcelReportProperties, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.getExpiredSessions(data)
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as {
            file: Blob,
            fileName: string,
        };
    }
)

export const getSoapNotesAdmin = createAsyncThunk(
    'soapNote/get/notes/admin',
    async (data: GetSoapNotesProperties, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.getSoapNotesAdmin(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as SoapNotesDataProperties
    }
)

export const getSoapNote = createAsyncThunk(
    'soapNote/get',
    async (data: GetSoapNoteProperties, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.getSoapNote(data)
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const unlockSoapNote = createAsyncThunk(
    'soapNote/unlock',
    async (data: UnlockSoapNoteProperties, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.unlockSoapNote(data)
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getSoapNotePDF = createAsyncThunk(
    'soapNote/get/pdf',
    async (data: GetSoapNoteProperties, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.getSoapNotePDF(data)
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response
    }
)

export const getBehaviorTreatmentOptions = createAsyncThunk(
    'soapNote/behaviorTreatment/options',
    async ({ clientId, sessionId }: { clientId: string, sessionId: string }, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.getBehaviorTreatmentOptions(clientId, sessionId);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response
    }
)

export const saveBehaviorTreatment = createAsyncThunk(
    'soapNote/behaviorTreatment/save',
    async ({ clientId, data }: SaveBehaviorTreatmentDataProperties, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.saveBehaviorTreatment({ clientId, data });
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const updateBehaviorTreatment = createAsyncThunk(
    'soapNote/behaviorTreatment/update',
    async ({ clientId, reportId, data }: UpdateBehaviorTreatmentDataProperties, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.updateBehaviorTreatment({ clientId, reportId, data });
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getBehaviorTreatmentModificationOptions = createAsyncThunk(
    'soapNote/behaviorTreatmentModification/options',
    async ({ clientId, sessionId }: { clientId: string, sessionId: string }, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.getBehaviorTreatmentModificationOptions(clientId, sessionId);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response
    }
)

export const saveBehaviorTreatmentModification = createAsyncThunk(
    'soapNote/behaviorTreatmentModification/save',
    async ({ clientId, data }: SaveBehaviorTreatmentModificationTypeDataProperties, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.saveBehaviorTreatmentModification({ clientId, data });
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const updateBehaviorTreatmentModification = createAsyncThunk(
    'soapNote/behaviorTreatmentModification/update',
    async ({ clientId, reportId, data }: UpdateBehaviorTreatmentModificationTypeDataProperties, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.updateBehaviorTreatmentModification({ clientId, reportId, data });
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getAssessmentOptions = createAsyncThunk(
    'soapNote/assessment/options',
    async ({ clientId, sessionId }: { clientId: string, sessionId: string }, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.getAssessmentOptions(clientId, sessionId);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response
    }
)

export const saveAssessment = createAsyncThunk(
    'soapNote/assessment/save',
    async ({ clientId, data }: SaveAssessmentTypeDataProperties, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.saveAssessment({ clientId, data });
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const updateAssessment = createAsyncThunk(
    'soapNote/assessment/update',
    async ({ clientId, reportId, data }: UpdateAssessmentTypeDataProperties, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.updateAssessment({ clientId, reportId, data });
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getDirectSupervisionOptions = createAsyncThunk(
    'soapNote/directSupervision/options',
    async ({ clientId, sessionId }: { clientId: string, sessionId: string }, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.getDirectSupervisionOptions(clientId, sessionId);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response
    }
)

export const saveDirectSupervision = createAsyncThunk(
    'soapNote/directSupervision/save',
    async ({ clientId, data }: SaveDirectSupervisionDataProperties, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.saveDirectSupervision({ clientId, data });
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const updateDirectSupervision = createAsyncThunk(
    'soapNote/directSupervision/update',
    async ({ clientId, reportId, data }: UpdateDirectSupervisionDataProperties, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.updateDirectSupervision({ clientId, reportId, data });
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getParentTrainingOptions = createAsyncThunk(
    'soapNote/parentTraining/options',
    async ({ clientId, sessionId }: { clientId: string, sessionId: string }, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.getParentTrainingOptions(clientId, sessionId);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response
    }
)

export const saveParentTraining = createAsyncThunk(
    'soapNote/parentTraining/save',
    async ({ clientId, data }: SaveParentTrainingTypeDataProperties, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.saveParentTraining({ clientId, data });
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const updateParentTraining = createAsyncThunk(
    'soapNote/parentTraining/update',
    async ({ clientId, reportId, data }: UpdateParentTrainingTypeDataProperties, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.updateParentTraining({ clientId, reportId, data });
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getTreatmentPlanningOptions = createAsyncThunk(
    'soapNote/TreatmentPlanning/options',
    async ({ clientId, sessionId }: { clientId: string, sessionId: string }, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.getTreatmentPlanningOptions(clientId, sessionId);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response
    }
)

export const saveTreatmentPlanning = createAsyncThunk(
    'soapNote/TreatmentPlanning/save',
    async ({ clientId, data }: SaveTreatmentPlanningTypeDataProperties, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.saveTreatmentPlanning({ clientId, data });
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const updateTreatmentPlanning = createAsyncThunk(
    'soapNote/TreatmentPlanning/update',
    async ({ clientId, reportId, data }: UpdateTreatmentPlanningTypeDataProperties, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.updateTreatmentPlanning({ clientId, reportId, data });
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getPendingSoapNotes = createAsyncThunk(
    'soapNote/pending-approval',
    async (data: GetPendingSoapNotesProperties, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.getPendingNotes(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const approveSoapNotes = createAsyncThunk(
    'soapNote/approve/all',
    async (data: ApprovePendingSoapNotesProperties, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.approvePendingNotes(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const approveSoapNote = createAsyncThunk(
    'soapNote/approve',
    async (data: ApprovePendingSoapNoteProperties, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.approvePendingNote(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getDSUSoapNotes = createAsyncThunk(
    'soapNote/dsu/get',
    async (data: GetSoapNotesProperties, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.getDSUNotes(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)


export const downloadDSUSoapNotes = createAsyncThunk(
    'soapNote/dsu/get/download',
    async (data: GetSoapNotesProperties, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.downloadDSUNotes(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as {
            file: Blob,
            fileName: string,
        };
    }
)

const soapNoteSlice = createSlice({
    name: "soapNote",
    initialState: initialSoapNoteState,
    reducers: {
        clearErrors(state) {
            state.error = initialSoapNoteState.error;
        }
    },
    extraReducers: (builder) => {
        //getDSUSoapNotes
        builder.addCase(getDSUSoapNotes.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getDSUSoapNotes.fulfilled, (state, action) => {
            state.loading = false;
            state.dsuSoapNotes = action.payload;
        })
        builder.addCase(getDSUSoapNotes.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //downloadDSUSoapNotes
        builder.addCase(downloadDSUSoapNotes.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(downloadDSUSoapNotes.fulfilled, (state, action) => {
            state.loading = false;
        })
        builder.addCase(downloadDSUSoapNotes.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //getPendingSoapNotes
        builder.addCase(getPendingSoapNotes.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getPendingSoapNotes.fulfilled, (state, action) => {
            state.loading = false;
            state.pendingSoapNotes = action.payload;
        })
        builder.addCase(getPendingSoapNotes.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //approveSoapNotes
        builder.addCase(approveSoapNotes.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(approveSoapNotes.fulfilled, (state, action) => {
            state.loading = false;
        })
        builder.addCase(approveSoapNotes.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //approveSoapNote
        builder.addCase(approveSoapNote.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(approveSoapNote.fulfilled, (state, action) => {
            state.loading = false;
        })
        builder.addCase(approveSoapNote.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //getSoapNote
        builder.addCase(getSoapNote.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getSoapNote.fulfilled, (state, action) => {
            state.loading = false;
            state.soapNote = action.payload;
        })
        builder.addCase(getSoapNote.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //getSoapNotesUser
        builder.addCase(getSoapNotesUser.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getSoapNotesUser.fulfilled, (state, action) => {
            state.loading = false;
            state.soapNotes = action.payload;
        })
        builder.addCase(getSoapNotesUser.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //getTelehealthLocations
        builder.addCase(getTelehealthLocations.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getTelehealthLocations.fulfilled, (state, action) => {
            state.loading = false;
            state.telehealtLocations = action.payload;
        })
        builder.addCase(getTelehealthLocations.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //getSoapNotesAdmin
        builder.addCase(getSoapNotesAdmin.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getSoapNotesAdmin.fulfilled, (state, action) => {
            state.loading = false;
            state.soapNotes = action.payload;
        })
        builder.addCase(getSoapNotesAdmin.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //getBehaviorTreatmentOptions
        builder.addCase(getBehaviorTreatmentOptions.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getBehaviorTreatmentOptions.fulfilled, (state, action) => {
            state.loading = false;
            state.behaviorTreatmentOptions = action.payload.options;
            state.soapNoteData = action.payload.data;
            state.locations = action.payload.locations
        })
        builder.addCase(getBehaviorTreatmentOptions.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //getBehaviorTreatmentModificationOptions
        builder.addCase(getBehaviorTreatmentModificationOptions.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getBehaviorTreatmentModificationOptions.fulfilled, (state, action) => {
            state.loading = false;
            state.behaviorTreatmentModificationOptions = action.payload.options;
            state.locations = action.payload.locations
            state.soapNoteData = action.payload.data;
        })
        builder.addCase(getBehaviorTreatmentModificationOptions.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //getDirectSupervisionOptions
        builder.addCase(getDirectSupervisionOptions.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getDirectSupervisionOptions.fulfilled, (state, action) => {
            state.loading = false;
            state.directSupervisionOptions = action.payload.options;
            state.locations = action.payload.locations
            state.soapNoteData = action.payload.data;
        })
        builder.addCase(getDirectSupervisionOptions.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //getAssessmentOptions
        builder.addCase(getAssessmentOptions.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getAssessmentOptions.fulfilled, (state, action) => {
            state.loading = false;
            state.assessmentOptions = action.payload.options;
            state.locations = action.payload.locations
            state.soapNoteData = action.payload.data;
        })
        builder.addCase(getAssessmentOptions.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //getParentTrainingOptions
        builder.addCase(getParentTrainingOptions.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getParentTrainingOptions.fulfilled, (state, action) => {
            state.loading = false;
            state.soapNoteData = action.payload.data;
            state.locations = action.payload.locations
            state.parentTrainingOptions = action.payload.options;
        })
        builder.addCase(getParentTrainingOptions.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //getTreatmentPlanningOptions
        builder.addCase(getTreatmentPlanningOptions.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getTreatmentPlanningOptions.fulfilled, (state, action) => {
            state.loading = false;
            state.soapNoteData = action.payload.data;
            state.locations = action.payload.locations
            state.treatmentPlanningOptions = action.payload.options;
        })
        builder.addCase(getTreatmentPlanningOptions.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //saveBehaviorTreatment
        builder.addCase(saveBehaviorTreatment.pending, (state) => {
            state.loading = true;
            state.error = initialSoapNoteState.error;
        })
        builder.addCase(saveBehaviorTreatment.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(saveBehaviorTreatment.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //saveBehaviorTreatmentModification
        builder.addCase(saveBehaviorTreatmentModification.pending, (state) => {
            state.loading = true;
            state.error = initialSoapNoteState.error;
        })
        builder.addCase(saveBehaviorTreatmentModification.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(saveBehaviorTreatmentModification.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //saveDirectSupervision
        builder.addCase(saveDirectSupervision.pending, (state) => {
            state.loading = true;
            state.error = initialSoapNoteState.error;
        })
        builder.addCase(saveDirectSupervision.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(saveDirectSupervision.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //saveParentTraining
        builder.addCase(saveParentTraining.pending, (state) => {
            state.loading = true;
            state.error = initialSoapNoteState.error;
        })
        builder.addCase(saveParentTraining.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(saveParentTraining.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //saveAssessment
        builder.addCase(saveAssessment.pending, (state) => {
            state.loading = true;
            state.error = initialSoapNoteState.error;
        })
        builder.addCase(saveAssessment.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(saveAssessment.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //saveTreatmentPlanning
        builder.addCase(saveTreatmentPlanning.pending, (state) => {
            state.loading = true;
            state.error = initialSoapNoteState.error;
        })
        builder.addCase(saveTreatmentPlanning.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(saveTreatmentPlanning.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //updateAssessment
        builder.addCase(updateAssessment.pending, (state) => {
            state.loading = true;
            state.error = initialSoapNoteState.error;
        })
        builder.addCase(updateAssessment.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(updateAssessment.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //updateBehaviorTreatmentModification
        builder.addCase(updateBehaviorTreatmentModification.pending, (state) => {
            state.loading = true;
            state.error = initialSoapNoteState.error;
        })
        builder.addCase(updateBehaviorTreatmentModification.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(updateBehaviorTreatmentModification.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //updateBehaviorTreatment
        builder.addCase(updateBehaviorTreatment.pending, (state) => {
            state.loading = true;
            state.error = initialSoapNoteState.error;
        })
        builder.addCase(updateBehaviorTreatment.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(updateBehaviorTreatment.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //updateDirectSupervision
        builder.addCase(updateDirectSupervision.pending, (state) => {
            state.loading = true;
            state.error = initialSoapNoteState.error;
        })
        builder.addCase(updateDirectSupervision.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(updateDirectSupervision.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //updateParentTraining
        builder.addCase(updateParentTraining.pending, (state) => {
            state.loading = true;
            state.error = initialSoapNoteState.error;
        })
        builder.addCase(updateParentTraining.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(updateParentTraining.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //updateTreatmentPlanning
        builder.addCase(updateTreatmentPlanning.pending, (state) => {
            state.loading = true;
            state.error = initialSoapNoteState.error;
        })
        builder.addCase(updateTreatmentPlanning.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(updateTreatmentPlanning.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //reset state
        builder.addCase(resetState, () => initialSoapNoteState);
    }
})

export const { clearErrors } = soapNoteSlice.actions
export default soapNoteSlice.reducer;