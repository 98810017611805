import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  CustomButton,
  CustomCheckBox,
  CustomTable,
  SearchSelect,
} from "../../../shared/uiComponents";
import { errorNormalizer } from "../../../shared/Helpers/functions";
import { Headers, TableBody } from "./tableInfo";
import { downloadFile, ReportFilters } from "../helpers";

import { DispatchProperties, useSelector } from "../../../redux/store";
import { GetBillingProperties } from "../../../redux/API/ClientAPIHelpers/billingProperties";
import {
  downloadBackdatedSessions,
  getBackdatedSessions,
  setOnlyNew,
} from "../../../redux/State/clientSlice/billingSlice";
import { getAdmins } from "../../../redux/State/userSlice";
import { DataProperties } from "../../../shared/uiComponents/Radio";

const BackdatedSessions = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params] = useSearchParams();

  const [onlySubmitted, setOnlySubmitted] = useState<boolean>(true);
  const [pageSize, setPageSize] = useState<number>(8);
  const [searchString, setSearchString] = useState<string>("");
  const [createdById, setCreatedById] = useState<string>("");
  const [usersData, setUsersData] = useState<Array<DataProperties>>([]);

  const sessions = useSelector((state) => state.billing.backdatedSessions);
  const loading = useSelector((state) => state.billing.loading);
  const filterData = useSelector((state) => state.billing.billingFilter);
  const loadingUsers = useSelector((state) => state.user.loading);

  const fetchData = useCallback(() => {
    const page = params.get("page") || "1";
    const pageSize = params.get("pageSize") || "8";
    const direction = params.get("direction") || "";
    const orderBy = params.get("orderBy") || "";
    const searchString = params.get("searchString") || "";
    const { clientId, endDate, startDate, userId, onlyNew } = filterData;

    if (!startDate || !endDate) return;
    const data: GetBillingProperties = {
      page,
      pageSize,
      startDate,
      endDate,
      onlyNew,
      direction,
      orderBy,
      searchString,
      userId,
      clientId,
      createdById,
      onlySubmitted,
    };

    dispatch(getBackdatedSessions(data))
      .then(unwrapResult)
      .catch(errorNormalizer);
  }, [dispatch, params, filterData, createdById, onlySubmitted]);

  const downloadHandler = useCallback(() => {
    const page = params.get("page") || "1";
    const direction = params.get("direction") || "";
    const orderBy = params.get("orderBy") || "";
    const searchString = params.get("searchString") || "";
    const pageSize = !!sessions.totalNumberOfItems
      ? sessions.totalNumberOfItems.toString()
      : "8";
    const { clientId, endDate, startDate, userId, onlyNew } = filterData;

    if (!startDate || !endDate) return;

    const data: GetBillingProperties = {
      page,
      pageSize,
      startDate,
      endDate,
      onlyNew,
      direction,
      orderBy,
      searchString,
      userId,
      clientId,
      createdById,
    };
    dispatch(downloadBackdatedSessions(data))
      .then(unwrapResult)
      .then((response) => {
        downloadFile(response);
        fetchData();
      })
      .catch(errorNormalizer);
  }, [dispatch, fetchData, params, sessions, filterData, createdById]);

  const onlyNewHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    dispatch(setOnlyNew(checked));
  };

  const showOnlySubmittedHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setOnlySubmitted(checked);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const page = params.get("page") || "1";
    const timeout = setTimeout(() => {
      dispatch(getAdmins({ page, pageSize: `${pageSize}`, searchString }))
        .then(unwrapResult)
        .then((response) => {
          if (!response.query) return;
          const data: Array<DataProperties> = response.query.map((user) => ({
            id: user.id,
            label: user.fullName,
          }));
          setUsersData(data);
        });
    }, 1000);
    return () => clearTimeout(timeout);
  }, [params, dispatch, pageSize, searchString]);

  const userHandler = (value: string) => {
    setCreatedById(value);
  };

  return (
    <>
      <>
        <div style={{ display: "flex", maxWidth: "720px", gap: "16px" }}>
          <div style={{ width: "250px", marginBottom: "16px" }}>
            <SearchSelect
              label="Find Admin:"
              searchString={searchString}
              setSearchString={setSearchString}
              data={usersData}
              setValue={userHandler}
              loadMore={{
                activate: true,
                setSize: setPageSize,
              }}
              loading={loadingUsers}
            />
          </div>
          <CustomCheckBox
            item={{
              label: "Show only submitted",
              id: "0",
              checked: onlySubmitted,
              disabled: loading,
            }}
            onChange={showOnlySubmittedHandler}
          />
        </div>
        <ReportFilters showUsers={true} />
        <div style={{ display: "flex", maxWidth: "720px", gap: "16px" }}>
          <CustomCheckBox
            item={{
              label: "Show only new",
              id: "0",
              checked: filterData.onlyNew,
              disabled: loading,
            }}
            onChange={onlyNewHandler}
          />
          <CustomButton
            onClick={downloadHandler}
            title={"Download"}
            loading={loading}
          />
        </div>
      </>
      <CustomTable
        headers={Headers}
        TableBody={TableBody}
        data={sessions}
        loading={loading}
        hasMobile={false}
      />
    </>
  );
};

export default BackdatedSessions;
