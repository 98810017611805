import { useEffect, ChangeEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";

import {
  CustomAlert,
  CustomButton,
  CustomCheckBox,
  CustomTable,
  Picker,
  Text,
} from "../../../shared/uiComponents";
import { dateNormalizer } from "../../../shared/Helpers/functions";
import { ButtonWrapper } from "../../../components/StyledComponents";
import SearchBar from "../../../components/SearchBar";
import { Headers, TableBody } from "./tableInfo";

import { DispatchProperties, useSelector } from "../../../redux/store";
import {
  getAvailableNotCredentialedBCBAs,
  getBillableUsers,
  getClientBillableUser,
  setBillingUser,
} from "../../../redux/State/clientSlice/userClientSlice";

const today = dayjs();

const NotCredentialedBCBA = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params, setParams] = useSearchParams();
  const { clientId } = useParams();

  const [open, setOpen] = useState<boolean>(false);
  const [startDateValue, setStartDateValue] = useState<Dayjs>(today);

  const bcbas = useSelector((state) => state.userClient.notCredentialedBCBAs);
  const billableUsers = useSelector((state) => state.userClient.billableUsers);
  const billingUser = useSelector((state) => state.userClient.billableUser);
  const loading = useSelector(
    (state) => state.userClient.loadingGetAvailableBCBAs
  );

  const startDateHandler = (date: Dayjs | null) => {
    if (!date) return;
    setStartDateValue(date);
  };

  useEffect(() => {
    if (!clientId) return;
    dispatch(getClientBillableUser(clientId));
    dispatch(getBillableUsers());
  }, [dispatch, clientId]);

  useEffect(() => {
    if (!clientId) return;
    const page = params.get("page") || "1";
    const pageSize = params.get("pageSize") || "8";
    const orderBy = params.get("orderBy") || "";
    const direction = params.get("direction") || "";
    const searchString = params.get("search") || "";
    const status = params.get("status") || "";

    dispatch(
      getAvailableNotCredentialedBCBAs({
        clientId,
        page,
        pageSize,
        orderBy,
        direction,
        searchString,
        status,
      })
    );
  }, [params, clientId, dispatch]);

  const onBillableUserChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    if (!name) return;
    const paramId = params.get("nc-billing-user");
    if (paramId === name || !paramId) {
      dispatch(setBillingUser(null));
      params.delete("nc-billing-user");
      setParams(params);
      return;
    }
    const user = billableUsers.find((x) => x.id === name);
    if (!user) return;
    dispatch(setBillingUser(user));
    params.set("nc-billing-user", name);
    setParams(params);
  };

  const setDateRangeHandler = () => {
    const startDate = dateNormalizer(startDateValue);
    const endDate = dateNormalizer(today);
    params.set("startDate", startDate);
    params.set("endDate", endDate);
    setParams(params);
    setOpen(false);
  };

  return (
    <div className="marginTop16">
      <div
        className="marginLeft8 billableWrapper"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div className="billableUserWrapper" onClick={() => setOpen(true)}>
          <Text title={"Choose billable BCBA: "} size="tinyBold" />
          <div>
            {billableUsers.map((user) => (
              <CustomCheckBox
                key={user.id}
                item={{
                  id: user.id,
                  label: user.fullName,
                  checked: !!billingUser
                    ? user.id === billingUser.id
                    : user.id === params.get("nc-billing-user"),
                }}
                onChange={onBillableUserChange}
              />
            ))}
          </div>
        </div>
        <CustomAlert
          open={open}
          onClose={() => setOpen(false)}
          Content={() => (
            <>
              <Picker.CustomDate
                label="Start Date"
                value={startDateValue}
                onChange={startDateHandler}
                disableFuture={true}
              />
              <ButtonWrapper>
                <CustomButton
                  title="Set"
                  onClick={setDateRangeHandler}
                  loading={loading}
                />
                <CustomButton
                  title="Close"
                  secondaryButton
                  onClick={() => setOpen(false)}
                  loading={loading}
                />
              </ButtonWrapper>
            </>
          )}
        />
        <SearchBar label="Find" />
      </div>
      <CustomTable
        headers={Headers}
        data={bcbas}
        TableBody={TableBody}
        loading={loading}
        hasMobile={false}
      />
    </div>
  );
};

export default NotCredentialedBCBA;
