import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { unwrapResult } from "@reduxjs/toolkit";
import dayjs, { Dayjs } from "dayjs";

import {
  dateNormalizer,
  errorNormalizer,
} from "../../../../shared/Helpers/functions";
import {
  CustomAlert,
  CustomButton,
  Picker,
} from "../../../../shared/uiComponents";
import { useCheckPermission } from "../../../../shared/Helpers/hooks";
import { AdminTypes } from "../../../../components/Menu";
import { ButtonWrapper } from "../../../../components/StyledComponents";
import { useResetCalendarData } from "../../Helpers/hooks";
import { PERMISSIONS } from "../../../../App/constants";

import { DispatchProperties, useSelector } from "../../../../redux/store";
import { setInfoEvent } from "../../../../redux/State/clientSlice/calendarSlice";
import { getSoapNotePDF } from "../../../../redux/State/exportSlice";
import { unlockSoapNote } from "../../../../redux/State/clientSlice/soapNoteSlice";
import { SoapNotesStatuses } from "../../../../redux/API/ClientAPIHelpers/dataCollectionProperties";
import { clearSessionInfo } from "../../../../redux/State/clientSlice/sessionSlice";

const initialExpirationDate = dayjs().add(2, "days");

const SoapNoteStatus = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<DispatchProperties>();
  const { resetData } = useResetCalendarData();
  const { permissionGranted } = useCheckPermission(PERMISSIONS.SOAPNOTE.UNLOCK);

  const loadingExport = useSelector((state) => state.export.loading);
  const loading = useSelector((state) => state.session.loading);
  const data = useSelector((state) => state.calendar.infoEventData);
  const role = useSelector((state) => state.account.role);

  const [unlockConfirm, setUnlockConfirm] = useState<boolean>(false);
  const [expirationDateValue, setExpirationDateValue] = useState<Dayjs | null>(
    initialExpirationDate
  );

  const expired =
    SoapNotesStatuses.expired48 === data?.reportStatus?.status ||
    SoapNotesStatuses.expiredWeek === data?.reportStatus?.status;

  const navigateEditSoapNote = () => {
    if (!data) return;
    dispatch(setInfoEvent(false));
    dispatch(clearSessionInfo());
    const { client, reportId, sessionId } = data;
    if (!reportId) {
      navigate(`/soap-notes/info?sessionId=${sessionId}&clientId=${client.id}`);
      return;
    }
    navigate(
      `/soap-notes/info?clientId=${client.id}&reportId=${reportId}&sessionId=${sessionId}`
    );
  };

  const viewSoapNoteHandler = () => {
    if (!data?.reportId) return;
    const reportId = data.reportId;
    dispatch(
      getSoapNotePDF({
        reportId,
        includeTrials: true,
      })
    )
      .then(unwrapResult)
      .then((response) => {
        const objectURL = URL.createObjectURL(response);
        window.open(objectURL);
      })
      .catch(errorNormalizer);
  };

  const unlockHandler = () => {
    if (!data?.reportId || !data?.client.id) return;
    const clientId = data.client.id;
    const reportId = data.reportId;

    dispatch(
      unlockSoapNote({
        reportId,
        clientId,
        nextExpirationDate: dateNormalizer(dayjs(expirationDateValue)),
      })
    )
      .then(unwrapResult)
      .then(() => {
        resetData();
        toast("Success");
      })
      .catch(errorNormalizer);
  };

  const findButtonTitle = (status: number | undefined) => {
    switch (status) {
      case SoapNotesStatuses.draft:
        return "Soap note incomplete";
      case SoapNotesStatuses.expired48 | SoapNotesStatuses.expiredWeek:
        return "Soap note expired";
      case SoapNotesStatuses.submitted:
        return "Soap note completed";
      case SoapNotesStatuses.unlocked:
        return "Soap note unlocked";
      case SoapNotesStatuses.pendingBT:
        return "Soap note pending BT";
      case SoapNotesStatuses.timeMismatch:
        return "Time/Location mismatch";
      default:
        return "Start soap note";
    }
  };

  return (
    <>
      <CustomButton
        title={findButtonTitle(data?.reportStatus?.status)}
        loading={loading || loadingExport}
        disabled={loading || loadingExport || expired}
        className="marginTop8"
        onClick={
          SoapNotesStatuses.submitted === data?.reportStatus?.status
            ? viewSoapNoteHandler
            : navigateEditSoapNote
        }
      />
      {expired &&
        role?.section.id === AdminTypes.admin &&
        permissionGranted && (
          <CustomButton
            title="Unlock soap note"
            onClick={() => setUnlockConfirm(true)}
            className="marginTop8"
            loading={loading}
          />
        )}
      <CustomAlert
        title="Set next expiration date"
        onClose={() => setUnlockConfirm(false)}
        open={unlockConfirm}
        Content={() => (
          <>
            <ButtonWrapper>
              <Picker.CustomDate
                label="Expiration date"
                value={expirationDateValue}
                onChange={setExpirationDateValue}
              />
            </ButtonWrapper>
            <ButtonWrapper>
              <CustomButton
                secondaryButton
                title="Cancel"
                onClick={() => setUnlockConfirm(false)}
              />
              <CustomButton title="Unlock" onClick={unlockHandler} />
            </ButtonWrapper>
          </>
        )}
      />
    </>
  );
};

export default SoapNoteStatus;
