import { Container } from "../../pages/SOAPNotes/Admin/helpers";
import { Loader } from "../StyledComponents";
import AuthorizationInfo from "./authorizationsInfo";
import Illuminate from "../../shared/Assets/logos/IlluminateLogoMain.png";

import { useSelector } from "../../redux/store";
import { IMG } from "../Menu/styledComponents";
import { Text } from "../../shared/uiComponents";
import { AdminTypes } from "../Menu";
// import PendingApproval from "../../pages/SOAPNotes/PendingApproval";

const Dashboard = () => {
  const loadingAccount = useSelector((state) => state.account.loading);
  const role = useSelector((state) => state.account.role);

  return (
    <Container style={{ textAlign: "center" }}>
      <Text title={"Welcome"} size="largeBold" className="marginTop16" />
      <IMG src={Illuminate} style={{ width: "40%" }} />
      {AdminTypes.bcba === role?.section.id && (
        <div style={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
          <AuthorizationInfo />
          {/* <PendingApproval /> */}
        </div>
      )}
      {loadingAccount && <Loader />}
    </Container>
  );
};

export default Dashboard;
