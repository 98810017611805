import { ChangeEvent, Dispatch, useEffect, useReducer, useState } from "react";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { SelectChangeEvent } from "@mui/material/Select";

import {
  CustomSelect,
  Text,
  CustomCheckBox,
  MultipleSelect,
  CustomInput,
} from "../../../shared/uiComponents";
import { validateName } from "../../../shared/Helpers/functions";
import { SaveDirectSupervisionProperties } from "../SoapNoteProperties/directSupervisionTypeProperties";
import { useDirectSupervisionOptions, useIsTelehealth } from "../hook";
import {
  SessionBehaviorsInfo,
  SessionTargetsInfo,
  SignatureInput,
  TelehealthLocations,
  TimeInfo,
} from "../helpers";
import {
  ACTIONS,
  ActionProperties,
  ERRORS,
  directSuperVisionErrorReducer,
  initialDirectSupervisionErrorState,
} from "./directSupervisionReducer";

import { DispatchProperties, useSelector } from "../../../redux/store";
import { getSupervisedSessionsInfo } from "../../../redux/State/clientSlice/sessionSlice";
import { GetSessionProperties } from "../../../redux/API/ClientAPIHelpers/calendarProperties";
import { LocationTypes } from "../../../redux/API/ClientAPIHelpers/soapNoteProperties";

const DirectSupervisionData = ({
  data,
  setData,
  disabled = false,
}: {
  data: SaveDirectSupervisionProperties;
  setData: Dispatch<ActionProperties>;
  disabled?: boolean;
}) => {
  const today = new Date();
  const todayString = dayjs(today).format("MM/DD/YYYY");
  const dispatch = useDispatch<DispatchProperties>();
  const [params] = useSearchParams();
  const { feedbackList, participantsList } = useDirectSupervisionOptions();

  const [errors, setErrors] = useReducer(
    directSuperVisionErrorReducer,
    initialDirectSupervisionErrorState
  );
  const [locations, setLocations] = useState<Array<LocationTypes>>([]);
  const isTelehealth = useIsTelehealth(data.locationId);

  const errorList = useSelector((state) => state.soapNote.error.errors);
  const supervisedSessions = useSelector(
    (state) => state.session.supervisedSessionInfo
  );
  const locationList = useSelector((state) => state.soapNote.locations);
  const { user } = useSelector((state) => state.session.sessionInfo);

  useEffect(() => {
    if (!!data.participantResponses.length) return;
    const payload = participantsList
      .filter((x) => x.isDefault)
      .map((y) => y.id);
    setData({ type: ACTIONS.setParticipants, payload });
  }, [participantsList, setData, data]);

  useEffect(() => {
    if (!errorList) return;
    const list = Object.keys(errorList);
    list.forEach((id) => setErrors({ type: ERRORS[id], payload: true }));
  }, [errorList]);

  useEffect(() => {
    const sessionId = params.get("sessionId");
    if (!sessionId) return;
    dispatch(getSupervisedSessionsInfo(sessionId));
  }, [params, dispatch]);

  useEffect(() => {
    if (!locationList) return;
    const id = data.supervisedSessionId;
    const choosenLocationType = supervisedSessions.find((x) => x.id === id)
      ?.location?.locationType;

    if (!choosenLocationType) {
      setLocations(locationList);
      return;
    }

    const locations = locationList.filter(
      (x) => x.locationType === choosenLocationType
    );
    setLocations(locations);
  }, [data, locationList, supervisedSessions]);

  const chooseSupervisedSessionHandler = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    setData({ payload: value, type: ACTIONS.setSupervisedSessionId });
  };

  const selectFeedbackHandler = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    if (typeof value === "string") return;
    setData({ payload: value, type: ACTIONS.setFeedbackResponses });
  };

  const promtedEffectivelyHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setData({
      type: ACTIONS.setPromtedEffectively,
      payload: checked,
    });
    if (!errors.PromtedEffectively) return;
    setErrors({ payload: false, type: ERRORS.PromtedEffectively });
  };

  const reinforcerImplementationHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = event.target;
    setData({
      type: ACTIONS.setReinforcerImplementation,
      payload: checked,
    });
    if (!errors.ReinforcerImplementation) return;
    setErrors({ payload: false, type: ERRORS.ReinforcerImplementation });
  };

  const dataEnteredCorrectlyHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = event.target;
    setData({
      type: ACTIONS.setDataEnteredCorrectly,
      payload: checked,
    });
    if (!errors.DataEnteredCorrectly) return;
    setErrors({ payload: false, type: ERRORS.DataEnteredCorrectly });
  };

  const implementationOfGoalHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setData({ payload: value, type: ACTIONS.setImplementationOfGoals });
    if (!errors.ImplementationOfGoal) return;
    setErrors({ payload: false, type: ERRORS.ImplementationOfGoal });
  };

  const responseToBehavioralFeedbackHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setData({ payload: value, type: ACTIONS.setResponseToBehavioralFeedback });
    if (!errors.ResponseToBehavioralFeedback) return;
    setErrors({ payload: false, type: ERRORS.ResponseToBehavioralFeedback });
  };

  const clientPerformanceHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setData({ payload: value, type: ACTIONS.setClientPerformance });
    if (!errors.ClientPerformance) return;
    setErrors({ payload: false, type: ERRORS.ClientPerformance });
  };

  const changesAndMeasurementsObservedHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setData({
      payload: value,
      type: ACTIONS.setChangesAndMeasurementsObserved,
    });
    if (!errors.ChangesAndMeasurementsObserved) return;
    setErrors({ payload: false, type: ERRORS.ChangesAndMeasurementsObserved });
  };

  const descriptionHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setData({ payload: value, type: ACTIONS.setDescriptionOfSession });
    if (!errors.DescriptionOfSession) return;
    setErrors({ payload: false, type: ERRORS.DescriptionOfSession });
  };

  const signatureHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setData({ payload: value.toUpperCase(), type: ACTIONS.setSignature });
    if (!errors.Signature) return;
    setErrors({ payload: false, type: ERRORS.Signature });
  };

  const supervisedSessionNameNormilizer = (info: GetSessionProperties) => ({
    id: info.id,
    name: `${info.client.fullName} - ${dayjs(info.startTime)
      .utc(false)
      .format("MM/DD/YYYY hh:mm A")} - ${dayjs(info.endTime)
      .utc(false)
      .format("hh:mm A")} - ${
      info.isCompleted ? "Session completed" : "Session not completed"
    }`,
  });

  const selectLocationHandler = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    setData({ payload: value, type: ACTIONS.setLocationId });
    if (!errors.LocationId) return;
    setErrors({ payload: false, type: ERRORS.LocationId });
  };

  const validateSignatureHandler = () => {
    if (validateName(data.signature)) return;
    setErrors({ payload: true, type: ERRORS.Signature });
  };

  const participantsHandler = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    if (typeof value === "string") return;
    setData({ type: ACTIONS.setParticipants, payload: value });
    if (!errors.ParticipantResponses) return;
    setErrors({ payload: false, type: ERRORS.ParticipantResponses });
  };

  return (
    <div>
      <TimeInfo disabled={disabled} />
      <CustomSelect
        label="Choose supervised session:"
        data={
          !!supervisedSessions
            ? supervisedSessions.map((x) => supervisedSessionNameNormilizer(x))
            : []
        }
        value={data.supervisedSessionId as string}
        setValue={chooseSupervisedSessionHandler}
        className="marginBottom16"
        disabled={disabled}
        error={errors.SupervisedSessionId}
      />
      <CustomSelect
        label="Location:"
        data={locations}
        value={!!data.locationId ? data.locationId : ""}
        error={errors.LocationId}
        setValue={selectLocationHandler}
        disabled={disabled}
        className="marginBottom16"
      />
      {isTelehealth && (
        <TelehealthLocations
          setData={setData}
          data={data}
          disabled={disabled}
        />
      )}
      <MultipleSelect
        label="Session Participants:"
        data={participantsList}
        initialValue={data.participantResponses}
        setValue={participantsHandler}
        error={errors.ParticipantResponses}
        className="marginBottom16"
        disabled={disabled}
      />
      <CustomCheckBox
        item={{
          label: "Prompted effectively?",
          checked: data.promptedEffectively,
          id: "0",
          disabled,
        }}
        onChange={promtedEffectivelyHandler}
      />
      <CustomCheckBox
        item={{
          label: `BT implemented the use of reinforcers properly according to the reinforcer rank order?`,
          checked: data.isReinforcerImplementationRight,
          id: "0",
          disabled,
        }}
        onChange={reinforcerImplementationHandler}
      />
      <CustomCheckBox
        item={{
          label: "Data entered correctly?",
          checked: data.dataEnteredCorrectly,
          id: "0",
          disabled,
        }}
        onChange={dataEnteredCorrectlyHandler}
      />
      <MultipleSelect
        label="Feedback provided to BT:"
        data={feedbackList}
        className="marginBottom16"
        setValue={selectFeedbackHandler}
        initialValue={data.feedbackResponses}
        error={errors.FeedbackResponses}
        disabled={disabled}
      />
      <CustomInput
        label="BT implementation of a goal from treatment plan:"
        value={data.implementationOfGoal}
        setValue={implementationOfGoalHandler}
        multiline={{ multiline: true, rowCount: 3 }}
        error={errors.ImplementationOfGoal}
        className="marginBottom16"
        disabled={disabled}
      />
      <CustomInput
        label="BT response to behavioral feedback:"
        value={data.responseToBehavioralFeedback}
        setValue={responseToBehavioralFeedbackHandler}
        multiline={{ multiline: true, rowCount: 3 }}
        className="marginBottom16"
        error={errors.ResponseToBehavioralFeedback}
        disabled={disabled}
      />
      <CustomInput
        label="Client performance on two (2) goals:"
        value={data.clientPerformance}
        setValue={clientPerformanceHandler}
        multiline={{ multiline: true, rowCount: 3 }}
        className="marginBottom16"
        error={errors.ClientPerformance}
        disabled={disabled}
      />
      <CustomInput
        label="Maladaptive behaviors, changes and measurements observed:"
        value={data.changesAndMeasurementsObserved}
        setValue={changesAndMeasurementsObservedHandler}
        multiline={{ multiline: true, rowCount: 3 }}
        className="marginBottom16"
        error={errors.ChangesAndMeasurementsObserved}
        disabled={disabled}
      />
      <Text
        title="Session summary"
        size="smallBold"
        className="marginBottom16 title"
      />
      <SessionTargetsInfo />
      <SessionBehaviorsInfo />
      <CustomInput
        label="Description of session: "
        value={data.descriptionOfSession}
        setValue={descriptionHandler}
        error={errors.DescriptionOfSession}
        className="marginBottom8"
        multiline={{
          multiline: true,
          rowCount: 3,
        }}
        disabled={disabled}
      />
      <div style={{ width: "250px" }}>
        <CustomInput
          label="Printed Name:"
          value={user.fullName.toUpperCase()}
          setValue={() => {}}
          disabled={true}
          className="marginBottom8 signature"
        />
        <SignatureInput
          value={data.signature}
          setValue={signatureHandler}
          error={errors.Signature}
          disabled={disabled}
          onBlur={validateSignatureHandler}
        />
      </div>
      <div>
        <Text title="Date of signature: " size="smallBold" />
        <Text title={todayString} />
      </div>
    </div>
  );
};

export default DirectSupervisionData;
