import { ChangeEvent, Dispatch, useEffect, useReducer } from "react";
import { SelectChangeEvent } from "@mui/material/Select";
import dayjs from "dayjs";

import {
  CustomInput,
  CustomSelect,
  Text,
  MultipleSelect,
} from "../../../shared/uiComponents";
import { validateName } from "../../../shared/Helpers/functions";
import { useAssessmentOptions, useIsTelehealth } from "../hook";
import {
  ACTIONS,
  ActionProperties,
  AssessmentErrorReducer,
  ERRORS,
  initialAssessmentErrorState,
} from "./assessmentReducer";
import {
  SessionBehaviorsInfo,
  SessionTargetsInfo,
  SignatureInput,
  TelehealthLocations,
  TimeInfo,
} from "../helpers";
import { SaveAssessmentTypeProperties } from "../SoapNoteProperties/assessmentTypeProperties";

import { useSelector } from "../../../redux/store";

const AssessmentData = ({
  data,
  setData,
  disabled = false,
}: {
  data: SaveAssessmentTypeProperties;
  setData: Dispatch<ActionProperties>;
  disabled?: boolean;
}) => {
  const today = new Date();
  const todayString = dayjs(today).format("MM/DD/YYYY");

  const [errors, setErrors] = useReducer(
    AssessmentErrorReducer,
    initialAssessmentErrorState
  );

  const {
    assessmentToolsList,
    peopleAttendanceList,
    treatmentPlanList,
    assessmentActivityList,
  } = useAssessmentOptions();

  const locationList = useSelector((state) => state.soapNote.locations);
  const { user } = useSelector((state) => state.session.sessionInfo);
  const errorList = useSelector((state) => state.soapNote.error.errors);

  const isTelehealth = useIsTelehealth(data.locationId);

  useEffect(() => {
    if (!!data.participantResponses.length) return;
    const payload = peopleAttendanceList
      .filter((x) => x.isDefault)
      .map((y) => y.id);
    setData({ type: ACTIONS.setParticipants, payload });
  }, [peopleAttendanceList, setData, data]);

  useEffect(() => {
    if (!errorList) return;
    const list = Object.keys(errorList);
    list.forEach((id) => setErrors({ type: ERRORS[id], payload: true }));
  }, [errorList]);

  const selectAssessmentToolHandler = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    if (typeof value === "string") return;
    setData({ payload: value, type: ACTIONS.setAssessmentTools });
    if (!errors.AssessmentToolResponses) return;
    setErrors({ payload: false, type: ERRORS.AssessmentToolResponses });
  };

  const selectAssessmentActivityHandler = (
    event: SelectChangeEvent<string[]>
  ) => {
    const { value } = event.target;
    if (typeof value === "string") return;
    setData({ payload: value, type: ACTIONS.setAssessmentActivities });
    if (!errors.AssessmentActivityResponses) return;
    setErrors({ payload: false, type: ERRORS.AssessmentActivityResponses });
  };

  const selectTreatmentPlanHAndler = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    if (typeof value === "string") return;
    setData({ payload: value, type: ACTIONS.setTreatmentPlans });
    if (!errors.TreatmentPlanResponses) return;
    setErrors({ payload: false, type: ERRORS.TreatmentPlanResponses });
  };

  const selectPeopleAttendanceHandler = (
    event: SelectChangeEvent<string[]>
  ) => {
    const { value } = event.target;
    if (typeof value === "string") return;
    setData({ payload: value, type: ACTIONS.setParticipants });
    if (!errors.ParticipantResponses) return;
    setErrors({ payload: false, type: ERRORS.ParticipantResponses });
  };

  const selectLocationHandler = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    setData({ payload: value, type: ACTIONS.setLocationId });
    if (!errors.LocationId) return;
    setErrors({ payload: false, type: ERRORS.LocationId });
  };

  const sessionSummaryHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setData({ payload: value, type: ACTIONS.setSessionSummary });
    if (!errors.SessionSummary) return;
    setErrors({ payload: false, type: ERRORS.SessionSummary });
  };

  const dataCollectedHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setData({ payload: value, type: ACTIONS.setDataCollected });
    if (!errors.DataCollected) return;
    setErrors({ payload: false, type: ERRORS.DataCollected });
  };

  const goalsCreatedHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setData({ payload: value, type: ACTIONS.setGoalsCreated });
    if (!errors.GoalsCreated) return;
    setErrors({ payload: false, type: ERRORS.GoalsCreated });
  };

  const signatureHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setData({ payload: value.toUpperCase(), type: ACTIONS.setSignature });
    if (!errors.Signature) return;
    setErrors({ payload: false, type: ERRORS.Signature });
  };

  const validateSignatureHandler = () => {
    if (validateName(data.signature)) return;
    setErrors({ payload: true, type: ERRORS.Signature });
  };

  return (
    <div>
      <TimeInfo disabled={disabled} />
      <CustomSelect
        label="Location:"
        data={locationList}
        value={!!data.locationId ? data.locationId : ""}
        setValue={selectLocationHandler}
        className={"marginBottom16"}
        disabled={disabled}
        error={errors.LocationId}
      />
      {isTelehealth && (
        <TelehealthLocations
          setData={setData}
          data={data}
          disabled={disabled}
        />
      )}
      <MultipleSelect
        label="Session Participants:"
        data={peopleAttendanceList}
        initialValue={data.participantResponses}
        setValue={selectPeopleAttendanceHandler}
        className={"marginBottom16"}
        error={errors.ParticipantResponses}
        disabled={disabled}
      />
      <MultipleSelect
        label="Assessment tools used: "
        data={assessmentToolsList}
        initialValue={data.assessmentToolResponses}
        setValue={selectAssessmentToolHandler}
        className="marginBottom16"
        error={errors.AssessmentToolResponses}
        disabled={disabled}
      />
      <MultipleSelect
        label="Assessment activities: "
        data={assessmentActivityList}
        initialValue={data.assessmentActivityResponses}
        setValue={selectAssessmentActivityHandler}
        className="marginBottom16"
        error={errors.AssessmentActivityResponses}
        disabled={disabled}
      />
      <MultipleSelect
        label="Treatment plan:"
        data={treatmentPlanList}
        initialValue={data.treatmentPlanResponses}
        setValue={selectTreatmentPlanHAndler}
        className="marginBottom16"
        error={errors.TreatmentPlanResponses}
        disabled={disabled}
      />
      <CustomInput
        label="Data collected/Behaviors observed:"
        value={data.dataCollected}
        setValue={dataCollectedHandler}
        multiline={{
          multiline: true,
          rowCount: 3,
        }}
        className="marginBottom16"
        error={errors.DataCollected}
        disabled={disabled}
      />
      <CustomInput
        label="Goals created:"
        value={data.goalsCreated}
        setValue={goalsCreatedHandler}
        multiline={{
          multiline: true,
          rowCount: 3,
        }}
        className="marginBottom16"
        error={errors.GoalsCreated}
        disabled={disabled}
      />
      <Text
        title="Session Details"
        size="smallBold"
        className="marginBottom16 title"
      />
      <SessionTargetsInfo />
      <SessionBehaviorsInfo />
      <CustomInput
        label="Session Summary: "
        value={data.sessionSummary}
        setValue={sessionSummaryHandler}
        className="marginBottom8"
        multiline={{
          multiline: true,
          rowCount: 3,
        }}
        error={errors.SessionSummary}
        disabled={disabled}
      />
      <div style={{ width: "300px" }}>
        <CustomInput
          label="Printed Name:"
          value={user.fullName.toUpperCase()}
          setValue={() => {}}
          disabled={true}
          className="marginBottom8 signature"
        />
        <SignatureInput
          value={data.signature}
          setValue={signatureHandler}
          error={errors.Signature}
          disabled={disabled}
          onBlur={validateSignatureHandler}
        />
      </div>
      <div>
        <Text title="Date of signature: " size="smallBold" />
        <Text title={todayString} />
      </div>
    </div>
  );
};

export default AssessmentData;
