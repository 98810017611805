import { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { DispatchProperties, useSelector } from "../../redux/store";
import {
    getAssessmentOptions,
    getBehaviorTreatmentModificationOptions,
    getBehaviorTreatmentOptions,
    getDirectSupervisionOptions,
    getParentTrainingOptions,
    getTreatmentPlanningOptions,
} from "../../redux/State/clientSlice/soapNoteSlice";

export const useIsTelehealth = (locationId: string | null) => {
    const [isTelehealth, setIsTelehealth] = useState<boolean>(false);
    const locationList = useSelector((state) => state.soapNote.locations);

    useEffect(() => {
        if (!locationId) return;
        const isTelehealth = locationList?.find((x) => x.id === locationId)?.telehealth;
        setIsTelehealth(!!isTelehealth);
    }, [locationId, locationList]);

    return isTelehealth;
}

export const useBehaviorTreatmentOptions = () => {
    const [params] = useSearchParams();
    const dispatch = useDispatch<DispatchProperties>();

    const promptLevelList = useSelector(
        (state) =>
            state.soapNote.behaviorTreatmentOptions.promptLevelOptions
    );
    const bariesrsList = useSelector(
        (state) =>
            state.soapNote.behaviorTreatmentOptions.barrierToTreatmentOptions
    );
    const behaviorTreatmentFutureList = useSelector(
        (state) =>
            state.soapNote.behaviorTreatmentOptions.behaviorTreatmentFuturePlanOptions
    );
    const technuquiesList = useSelector(
        (state) =>
            state.soapNote.behaviorTreatmentOptions.behaviorTreatmentTechniqueOptions
    );
    const clientMoodList = useSelector(
        (state) =>
            state.soapNote.behaviorTreatmentOptions.clientMoodOptions
    );
    const clientResponseToTreatmentList = useSelector(
        (state) =>
            state.soapNote.behaviorTreatmentOptions.clientResponseToTreatmentOptions
    );
    const levelOfMaladaptiveBehaviorList = useSelector(
        (state) =>
            state.soapNote.behaviorTreatmentOptions.levelOfMaladaptiveBehaviorOptions
    );
    const maladaptiveBehaviorTechniquesList = useSelector(
        (state) =>
            state.soapNote.behaviorTreatmentOptions.maladaptiveBehaviorTechniqueOptions
    );
    const reinforsmentList = useSelector(
        (state) =>
            state.soapNote.behaviorTreatmentOptions.reinforcementOptions
    );
    const skillDomainAddressedList = useSelector(
        (state) =>
            state.soapNote.behaviorTreatmentOptions.skillDomainAddressedOptions
    );
    const participantsList = useSelector(
        (state) =>
            state.soapNote.behaviorTreatmentOptions.behaviorTreatmentParticipantOptions
    );

    useEffect(() => {
        const clientId = params.get("clientId");
        const sessionId = params.get("sessionId");
        if (!clientId || !sessionId) return;
        dispatch(getBehaviorTreatmentOptions({ clientId, sessionId }));
    }, [params, dispatch]);

    return {
        bariesrsList,
        technuquiesList,
        reinforsmentList,
        behaviorTreatmentFutureList,
        clientResponseToTreatmentList,
        levelOfMaladaptiveBehaviorList,
        maladaptiveBehaviorTechniquesList,
        promptLevelList,
        skillDomainAddressedList,
        clientMoodList,
        participantsList,
    }
}

export const useAssessmentOptions = () => {
    const [params] = useSearchParams();
    const dispatch = useDispatch<DispatchProperties>();

    const assessmentToolsList = useSelector(
        (state) =>
            state.soapNote.assessmentOptions.assessmentToolOptions
    );

    const treatmentPlanList = useSelector(
        (state) =>
            state.soapNote.assessmentOptions.treatmentPlanOptions
    );

    const peopleAttendanceList = useSelector(
        (state) =>
            state.soapNote.assessmentOptions.assessmentParticipantOptions
    );

    const assessmentActivityList = useSelector(
        (state) =>
            state.soapNote.assessmentOptions.assessmentActivityOptions
    );

    useEffect(() => {
        const clientId = params.get("clientId");
        const sessionId = params.get("sessionId");
        if (!clientId || !sessionId) return;
        dispatch(getAssessmentOptions({ clientId, sessionId }));
    }, [params, dispatch]);

    return {
        assessmentToolsList,
        treatmentPlanList,
        peopleAttendanceList,
        assessmentActivityList,
    }
}

export const useBehaviorTreatmentModificationOptions = () => {
    const [params] = useSearchParams();
    const dispatch = useDispatch<DispatchProperties>();

    const interventionsList = useSelector(
        (state) =>
            state.soapNote.behaviorTreatmentModificationOptions.behaviorTreatmentModificationInterventionOptions
    );

    const barriersList = useSelector(
        (state) =>
            state.soapNote.behaviorTreatmentModificationOptions.barrierToTreatmentOptions
    );

    const reinforcersList = useSelector(
        (state) =>
            state.soapNote.behaviorTreatmentModificationOptions.reinforcementOptions
    );

    const responseToInterventionList = useSelector(
        (state) =>
            state.soapNote.behaviorTreatmentModificationOptions.responseToInterventionOptions
    )

    const participantsList = useSelector(
        (state) =>
            state.soapNote.behaviorTreatmentModificationOptions.behaviorTreatmentModificationParticipantOptions
    );

    useEffect(() => {
        const clientId = params.get("clientId");
        const sessionId = params.get("sessionId");
        if (!clientId || !sessionId) return;
        dispatch(getBehaviorTreatmentModificationOptions({ clientId, sessionId }));
    }, [params, dispatch]);

    return {
        interventionsList,
        barriersList,
        reinforcersList,
        responseToInterventionList,
        participantsList,
    }
}

export const useDirectSupervisionOptions = () => {
    const [params] = useSearchParams();
    const dispatch = useDispatch<DispatchProperties>();

    const feedbackList = useSelector(
        (state) => state.soapNote.directSupervisionOptions.feedbackOptions
    );
    const participantsList = useSelector(
        (state) =>
            state.soapNote.directSupervisionOptions.directSupervisionParticipantOptions
    );

    useEffect(() => {
        const clientId = params.get("clientId");
        const sessionId = params.get("sessionId");
        if (!clientId || !sessionId) return;
        dispatch(getDirectSupervisionOptions({ clientId, sessionId }));

    }, [params, dispatch]);

    return {
        feedbackList,
        participantsList,
    }
}

export const useParentTrainingOptions = () => {

    const [params] = useSearchParams();
    const dispatch = useDispatch<DispatchProperties>();

    const peopleInAttendanceList = useSelector(
        (state) =>
            state.soapNote.parentTrainingOptions.parentTrainingParticipantOptions
    );
    const directParentTrainingList = useSelector(
        (state) =>
            state.soapNote.parentTrainingOptions.directParentTrainingOptions
    );

    useEffect(() => {
        const clientId = params.get("clientId");
        const sessionId = params.get("sessionId");
        if (!clientId || !sessionId) return;
        dispatch(getParentTrainingOptions({ clientId, sessionId }));
    }, [params, dispatch]);

    return {
        peopleInAttendanceList,
        directParentTrainingList,
    }
}

export const useTreatmentPlanningOptions = () => {

    const [params] = useSearchParams();
    const dispatch = useDispatch<DispatchProperties>();

    const participantsList = useSelector(
        (state) =>
            state.soapNote.treatmentPlanningOptions.treatmentPlanningParticipantOptions
    );
    const treatmentUpdatesList = useSelector(
        (state) =>
            state.soapNote.treatmentPlanningOptions.treatmentUpdateOptions
    );

    useEffect(() => {
        const clientId = params.get("clientId");
        const sessionId = params.get("sessionId");
        if (!clientId || !sessionId) return;
        dispatch(getTreatmentPlanningOptions({ clientId, sessionId }));
    }, [params, dispatch]);

    return {
        participantsList,
        treatmentUpdatesList,
    }
}