import { SaveBehaviorTreatmentProperties } from "../SoapNoteProperties/behaviorTreatmentTypeProperties";

export enum ACTIONS {
    setState = "setState",
    setBarrierToTreatmentResponses = "setBarrierToTreatmentResponses",
    setBehaviorTreatmentTechniquesResponses = "setBehaviorTreatmentTechniquesResponses",
    setMaladaptiveBehaviors = "setMaladaptiveBehaviors",
    setPromptLevelsResponses = "setPromptLevelsResponses",
    setReinforcementResponses = "setReinforcementResponses",
    setMaladaptiveTechniquesResponses = "setMaladaptiveTechniquesResponses",
    setDate = "setDate",
    setEndTime = "setEndTime",
    setStartTime = "setStartTime",
    setLocationId = "setLocationId",
    setSessionDescription = "setSessionDescription",
    setSessionId = "setSessionId",
    setSignature = "setSignature",
    setPrintedName = "setPrintedName",
    setSupervisorId = "setSupervisorId",
    setSupervisor = "setSupervisor",
    setBarrierToTreatmentOther = "setBarrierToTreatmentOther",
    setHowBarrierAffect = "setHowBarrierAffect",
    setSkillDomainAddressed = "setSkillDomainAddressed",
    setWorkedGoals = "setWorkedGoals",
    setLevelOfMaladaptiveBehavior = "setLevelOfMaladaptiveBehavior",
    setFrequencyAndDurationOfMaladaptiveBehaviors = "setFrequencyAndDurationOfMaladaptiveBehaviors",
    setClientResponseToTreatment = "setClientResponseToTreatment",
    setClientMoodResponses = "setClientMoodResponses",
    setFuturePlanResponses = "setFuturePlanResponses",
    setParticipants = "setParticipants",
}

export interface ActionProperties {
    type: keyof typeof ACTIONS,
    payload: any,
};

export const initialBehaviorTreatmentState: SaveBehaviorTreatmentProperties = {
    barrierToTreatmentResponses: [],
    behaviorTreatmentTechniquesResponses: [],
    skillDomainAddressedResponses: [],
    clientMoodResponses: [],
    promptLevelsResponses: [],
    reinforcementResponses: [],
    maladaptiveBehaviorTechniquesResponses: [],
    behaviorTreatmentFuturePlanResponses: [],
    barrierToTreatmentOther: "",
    howBarrierAffect: "",
    date: "",
    workedGoals: "",
    endTime: "",
    locationId: null,
    maladaptiveBehaviors: "",
    notes: "",
    sessionDescription: "",
    printedName: "",
    sessionId: '',
    signature: "",
    startTime: "",
    supervisorId: "",
    supervisor: {
        id: "",
        email: "",
        firstName: "",
        fullName: "",
        lastName: "",
        role: {
            id: "",
            name: "",
            section: {
                id: 0,
                name: "",
            }
        },
    },
    frequencyAndDurationOfMaladaptiveBehaviors: "",
    submit: false,
    responseToIntervention: null,
    levelOfMaladaptiveBehavior: null,
    forcedOperation: false,
    clientResponseToTreatment: null,
    participantResponses: [],
}

export const behaviorTreatmentReducer = (
    state: SaveBehaviorTreatmentProperties,
    action: ActionProperties
): SaveBehaviorTreatmentProperties => {
    const { type, payload } = action;

    switch (type) {
        case ACTIONS.setState:
            return {
                ...state,
                ...payload,
            }
        case ACTIONS.setLocationId:
            return {
                ...state,
                locationId: payload,
            }
        case ACTIONS.setSupervisorId:
            return {
                ...state,
                supervisorId: payload,
            }
        case ACTIONS.setSupervisor:
            return {
                ...state,
                supervisor: payload,
            }
        case ACTIONS.setHowBarrierAffect:
            return {
                ...state,
                howBarrierAffect: payload,
            }
        case ACTIONS.setBarrierToTreatmentResponses:
            return {
                ...state,
                barrierToTreatmentResponses: payload,
            }
        case ACTIONS.setBehaviorTreatmentTechniquesResponses:
            return {
                ...state,
                behaviorTreatmentTechniquesResponses: payload,
            }
        case ACTIONS.setMaladaptiveTechniquesResponses:
            return {
                ...state,
                maladaptiveBehaviorTechniquesResponses: payload,
            }
        case ACTIONS.setReinforcementResponses:
            return {
                ...state,
                reinforcementResponses: payload,
            }
        case ACTIONS.setPromptLevelsResponses:
            return {
                ...state,
                promptLevelsResponses: payload,
            }
        case ACTIONS.setMaladaptiveBehaviors:
            return {
                ...state,
                maladaptiveBehaviors: payload,
            }
        case ACTIONS.setSessionDescription:
            return {
                ...state,
                sessionDescription: payload,
            }
        case ACTIONS.setWorkedGoals:
            return {
                ...state,
                workedGoals: payload,
            }
        case ACTIONS.setBarrierToTreatmentOther:
            return {
                ...state,
                barrierToTreatmentOther: payload,
            }
        case ACTIONS.setSkillDomainAddressed:
            return {
                ...state,
                skillDomainAddressedResponses: payload,
            }
        case ACTIONS.setLevelOfMaladaptiveBehavior:
            return {
                ...state,
                levelOfMaladaptiveBehavior: payload,
            }
        case ACTIONS.setFrequencyAndDurationOfMaladaptiveBehaviors:
            return {
                ...state,
                frequencyAndDurationOfMaladaptiveBehaviors: payload,
            }
        case ACTIONS.setClientResponseToTreatment:
            return {
                ...state,
                clientResponseToTreatment: payload,
            }
        case ACTIONS.setSignature:
            return {
                ...state,
                signature: payload,
            }
        case ACTIONS.setClientMoodResponses:
            return {
                ...state,
                clientMoodResponses: payload,
            }
        case ACTIONS.setFuturePlanResponses:
            return {
                ...state,
                behaviorTreatmentFuturePlanResponses: payload,
            }
        case ACTIONS.setParticipants:
            return {
                ...state,
                participantResponses: payload,
            }
        default:
            return state;
    }
}

export enum ERRORS {
    BarrierToTreatmentResponses = "BarrierToTreatmentResponses",
    BehaviorTreatmentFuturePlanResponses = "BehaviorTreatmentFuturePlanResponses",
    BarrierToTreatmentOther = "BarrierToTreatmentOther",
    BehaviorTreatmentTechniquesResponses = "BehaviorTreatmentTechniquesResponses",
    ClientMoodResponses = "ClientMoodResponses",
    ClientResponseToTreatment = "ClientResponseToTreatment",
    FrequencyAndDurationOfMaladaptiveBehaviors =
    "FrequencyAndDurationOfMaladaptiveBehaviors",
    HowBarrierAffect = "HowBarrierAffect",
    LevelOfMaladaptiveBehavior = "LevelOfMaladaptiveBehavior",
    LocationId = "LocationId",
    MaladaptiveBehaviorTechniquesResponses =
    "MaladaptiveBehaviorTechniquesResponses",
    Notes = "Notes",
    Reinforcement = "Reinforcement",
    SessionDescription = "SessionDescription",
    Signature = "Signature",
    SkillDomainAddressedResponses = "SkillDomainAddressedResponses",
    WorkedGoals = "WorkedGoals",
    ParticipantResponses = "ParticipantResponses",
};

interface BehaviorTreatmentErrorProperties {
    BarrierToTreatmentResponses: boolean;
    BehaviorTreatmentFuturePlanResponses: boolean;
    BarrierToTreatmentOther: boolean;
    BehaviorTreatmentTechniquesResponses: boolean;
    ClientMoodResponses: boolean;
    ClientResponseToTreatment: boolean;
    FrequencyAndDurationOfMaladaptiveBehaviors: boolean;
    HowBarrierAffect: boolean;
    LevelOfMaladaptiveBehavior: boolean;
    LocationId: boolean;
    MaladaptiveBehaviorTechniquesResponses: boolean;
    Notes: boolean;
    Reinforcement: boolean;
    SessionDescription: boolean;
    Signature: boolean;
    SkillDomainAddressedResponses: boolean;
    WorkedGoals: boolean;
    ParticipantResponses: boolean;
}

export interface ErrorActionProperties {
    type: keyof typeof ERRORS,
    payload: any,
};

export const initialBehaviorTreatmentErrorState: BehaviorTreatmentErrorProperties = {
    LocationId: false,
    BarrierToTreatmentOther: false,
    BarrierToTreatmentResponses: false,
    BehaviorTreatmentFuturePlanResponses: false,
    BehaviorTreatmentTechniquesResponses: false,
    ClientMoodResponses: false,
    ClientResponseToTreatment: false,
    FrequencyAndDurationOfMaladaptiveBehaviors: false,
    HowBarrierAffect: false,
    LevelOfMaladaptiveBehavior: false,
    MaladaptiveBehaviorTechniquesResponses: false,
    Notes: false,
    Reinforcement: false,
    SessionDescription: false,
    Signature: false,
    SkillDomainAddressedResponses: false,
    WorkedGoals: false,
    ParticipantResponses: false,
}

export const behaviorTreatmentErrorReducer = (state: BehaviorTreatmentErrorProperties, action: ErrorActionProperties) => {
    const { payload, type } = action;

    switch (type) {
        case ERRORS[type]: {
            return {
                ...state,
                [type]: payload,
            }
        }
        default:
            return state;
    }
}