import { FetchAPI } from "../Main/fetchAPI";
import { CLIENT, CLIENT_USERS, CLIENT_SOAP_NOTES, SOAP_NOTES, SESSIONS } from "../constants";

import {
    SaveAssessmentTypeDataProperties,
    UpdateAssessmentTypeDataProperties
} from "../../../pages/SOAPNote/SoapNoteProperties/assessmentTypeProperties";
import {
    SaveBehaviorTreatmentModificationTypeDataProperties,
    UpdateBehaviorTreatmentModificationTypeDataProperties
} from "../../../pages/SOAPNote/SoapNoteProperties/behaviorTreatmentModificationTypeProperties";
import {
    SaveBehaviorTreatmentDataProperties,
    UpdateBehaviorTreatmentDataProperties
} from "../../../pages/SOAPNote/SoapNoteProperties/behaviorTreatmentTypeProperties";
import {
    SaveDirectSupervisionDataProperties,
    UpdateDirectSupervisionDataProperties
} from "../../../pages/SOAPNote/SoapNoteProperties/directSupervisionTypeProperties";
import {
    SaveParentTrainingTypeDataProperties,
    UpdateParentTrainingTypeDataProperties
} from "../../../pages/SOAPNote/SoapNoteProperties/parentTrainingTypeProperties";
import {
    SaveTreatmentPlanningTypeDataProperties,
    UpdateTreatmentPlanningTypeDataProperties
} from "../../../pages/SOAPNote/SoapNoteProperties/treatmentPlanningTypeProperties";
import {
    ApprovePendingSoapNoteProperties,
    ApprovePendingSoapNotesProperties,
    GetExcelReportProperties,
    GetPendingSoapNotesProperties,
    GetSoapNoteProperties,
    GetSoapNotesProperties,
    UnlockSoapNoteProperties,
    UpdateBillingCredentialedProperties,
} from "./soapNoteProperties";

export const SoapNote = {
    getPendingNotes: (data: GetPendingSoapNotesProperties) => {
        const {
            page,
            pageSize,
            direction,
            orderBy,
            searchString,
            status,
            endDate,
            startDate,
            clientId,
            userId,
            includeApproved,
        } = data;
        const query = new URLSearchParams();

        if (!!page) query.set("page", page);
        if (!!pageSize) query.set("pageSize", pageSize);
        if (!!searchString) query.set("searchString", searchString);
        if (!!direction) query.set("direction", direction);
        if (!!orderBy) query.set("orderBy", orderBy);
        if (!!status) query.set("status", status);
        if (!!startDate) query.set("startDate", startDate);
        if (!!endDate) query.set("endDate", endDate);
        if (!!clientId) query.set("clientId", clientId);
        if (!!userId) query.set("userId", userId);
        if (!!includeApproved) query.set("includeApproved", `${includeApproved}`);

        return FetchAPI.getJson({ urlStr: `${SOAP_NOTES}/pending-approval?${query.toString()}` })
    },
    approvePendingNotes: (data: ApprovePendingSoapNotesProperties) => {
        const {
            page,
            pageSize,
            direction,
            orderBy,
            searchString,
            status,
            endDate,
            startDate,
            clientId,
            userId,
            includeApproved,
            signature,
        } = data;
        const query = new URLSearchParams();

        if (!!page) query.set("page", page);
        if (!!pageSize) query.set("pageSize", pageSize);
        if (!!searchString) query.set("searchString", searchString);
        if (!!direction) query.set("direction", direction);
        if (!!orderBy) query.set("orderBy", orderBy);
        if (!!status) query.set("status", status);
        if (!!startDate) query.set("startDate", startDate);
        if (!!endDate) query.set("endDate", endDate);
        if (!!clientId) query.set("clientId", clientId);
        if (!!userId) query.set("userId", userId);
        if (typeof includeApproved === 'boolean') query.set("includeApproved", `${includeApproved}`);

        return FetchAPI.postJson({ urlStr: `${SOAP_NOTES}/pending-approval/approve?${query.toString()}`, data: { signature } })
    },
    approvePendingNote: (data: ApprovePendingSoapNoteProperties) => {
        const { reportId, signature } = data;
        return FetchAPI.postJson({ urlStr: `${SOAP_NOTES}/${reportId}/approve`, data: { signature } })

    },
    updateBillingCredentialed: (data: UpdateBillingCredentialedProperties) => FetchAPI.putJson({
        urlStr: `${CLIENT_USERS}/${data.userId}/soap-notes/update-billing-credentialed`,
        data,
    }),
    getSoapNotesUser: (data: GetSoapNotesProperties) => {
        const {
            page,
            pageSize,
            direction,
            orderBy,
            searchString,
            status,
            endDate,
            startDate,
            clientId,
            userId,
        } = data;
        const query = new URLSearchParams();

        query.set('page', page);
        query.set('pageSize', pageSize);
        if (!!searchString) query.set("searchString", searchString);
        if (!!direction) query.set("direction", direction);
        if (!!orderBy) query.set("orderBy", orderBy);
        if (!!status) query.set("status", status);
        if (!!endDate) query.set("endDate", endDate);
        if (!!startDate) query.set("startDate", startDate);
        if (!!clientId) query.set("clientId", clientId);
        if (!!userId) query.set("userId", userId);

        return FetchAPI.getJson({ urlStr: `${SOAP_NOTES}?${query.toString()}` })
    },
    getPendingDSUSoapNotes: (data: GetExcelReportProperties) => {
        const {
            clientId,
            userId,
            endDate,
            startDate,
        } = data;
        const query = new URLSearchParams();

        if (!!userId) query.set("userId", userId);
        if (!!clientId) query.set("clientId", clientId);
        if (!!endDate) query.set("endDate", endDate);
        if (!!startDate) query.set("startDate", startDate);

        return FetchAPI.getJson({ urlStr: `${SOAP_NOTES}/pending-dsu/xlsx?${query.toString()}` })
    },
    getDSUNotes: (data: GetSoapNotesProperties) => {
        const {
            page,
            pageSize,
            direction,
            orderBy,
            searchString,
            status,
            endDate,
            startDate,
            clientId,
            userId,
        } = data;
        const query = new URLSearchParams();

        query.set('page', page);
        query.set('pageSize', pageSize);
        if (!!searchString) query.set("searchString", searchString);
        if (!!direction) query.set("direction", direction);
        if (!!orderBy) query.set("orderBy", orderBy);
        if (!!status) query.set("status", status);
        if (!!endDate) query.set("endDate", endDate);
        if (!!startDate) query.set("startDate", startDate);
        if (!!clientId) query.set("clientId", clientId);
        if (!!userId) query.set("userId", userId);

        return FetchAPI.getJson({ urlStr: `${SOAP_NOTES}/dsu?${query.toString()}` })
    },
    downloadDSUNotes: (data: GetSoapNotesProperties) => {
        const {
            page,
            pageSize,
            direction,
            orderBy,
            searchString,
            status,
            endDate,
            startDate,
            clientId,
            userId,
        } = data;
        const query = new URLSearchParams();
        query.set('page', page);
        query.set('pageSize', pageSize);
        if (!!searchString) query.set("searchString", searchString);
        if (!!direction) query.set("direction", direction);
        if (!!orderBy) query.set("orderBy", orderBy);
        if (!!status) query.set("status", status);
        if (!!endDate) query.set("endDate", endDate);
        if (!!startDate) query.set("startDate", startDate);
        if (!!clientId) query.set("clientId", clientId);
        if (!!userId) query.set("userId", userId);

        return FetchAPI.getJson({ urlStr: `${SOAP_NOTES}/dsu/xlsx?${query.toString()}` })
    },
    getExpiredSessions: (data: GetExcelReportProperties) => {
        const {
            clientId,
            userId,
            endDate,
            startDate,
        } = data;
        const query = new URLSearchParams();

        if (!!userId) query.set("userId", userId);
        if (!!clientId) query.set("clientId", clientId);
        if (!!endDate) query.set("endDate", endDate);
        if (!!startDate) query.set("startDate", startDate);

        return FetchAPI.getJson({ urlStr: `${SESSIONS}/expired/xlsx?${query.toString()}` })
    },
    getSoapNotesAdmin: (data: GetSoapNotesProperties) => {
        const {
            clientId,
            page,
            pageSize,
            direction,
            orderBy,
            searchString,
            status,
            startDate,
            endDate,
            userId,
        } = data;
        const query = new URLSearchParams();

        query.set('page', page);
        query.set('pageSize', pageSize);
        if (!!searchString) query.set("searchString", searchString);
        if (!!direction) query.set("direction", direction);
        if (!!orderBy) query.set("orderBy", orderBy);
        if (!!status) query.set("status", status);
        if (!!startDate) query.set("startDate", startDate);
        if (!!endDate) query.set("endDate", endDate);
        if (!!clientId) query.set("clientId", clientId);
        if (!!userId) query.set("userId", userId);

        return FetchAPI.getJson({
            urlStr: `${CLIENT}/admin/soap-notes?${query.toString()}`,
        })
    },
    getSoapNote: ({ clientId, reportId }: GetSoapNoteProperties) => FetchAPI.getJson({
        urlStr: `${CLIENT_SOAP_NOTES(clientId)}/${reportId}`,
    }),
    getSoapNotePDF: ({ clientId, reportId }: GetSoapNoteProperties) => FetchAPI.getJson({
        urlStr: `${CLIENT_SOAP_NOTES(clientId)}/${reportId}/pdf`,
    }),
    unlockSoapNote: ({ clientId, reportId, nextExpirationDate }: UnlockSoapNoteProperties) => FetchAPI.postJson({
        urlStr: `${CLIENT_SOAP_NOTES(clientId)}/${reportId}/unlock`,
        data: { nextExpirationDate }
    }),
    //Direct Supervision
    getDirectSupervisionOptions: (clientId: string, sessionId: string) => {
        const query = new URLSearchParams();
        query.set("sessionId", sessionId);

        return FetchAPI.getJson({
            urlStr: `${CLIENT_SOAP_NOTES(clientId)}/direct-supervision/options?${query.toString()}`,
        })
    },
    saveDirectSupervision: ({ clientId, data }: SaveDirectSupervisionDataProperties) => FetchAPI.postJson({
        urlStr: `${CLIENT_SOAP_NOTES(clientId)}/direct-supervision`,
        data,
    }),
    updateDirectSupervision: ({ clientId, reportId, data }: UpdateDirectSupervisionDataProperties) =>
        FetchAPI.putJson({
            urlStr: `${CLIENT_SOAP_NOTES(clientId)}/direct-supervision/${reportId}`,
            data,
        }),
    //Behavior Treatment
    getBehaviorTreatmentOptions: (clientId: string, sessionId: string) => {
        const query = new URLSearchParams();
        query.set("sessionId", sessionId);

        return FetchAPI.getJson({
            urlStr: `${CLIENT_SOAP_NOTES(clientId)}/behavior-treatment/options?${query.toString()}`,
        })
    },
    saveBehaviorTreatment: ({ clientId, data }: SaveBehaviorTreatmentDataProperties) => FetchAPI.postJson({
        urlStr: `${CLIENT_SOAP_NOTES(clientId)}/behavior-treatment`,
        data,
    }),
    updateBehaviorTreatment: ({ clientId, reportId, data }: UpdateBehaviorTreatmentDataProperties) =>
        FetchAPI.putJson({
            urlStr: `${CLIENT_SOAP_NOTES(clientId)}/behavior-treatment/${reportId}`,
            data,
        }),
    //Behavior Treatment Modification
    getBehaviorTreatmentModificationOptions: (clientId: string, sessionId: string) => {
        const query = new URLSearchParams();
        query.set("sessionId", sessionId);

        return FetchAPI.getJson({
            urlStr: `${CLIENT_SOAP_NOTES(clientId)}/behavior-treatment-modification/options?${query.toString()}`,
        })
    },
    saveBehaviorTreatmentModification: ({ clientId, data }: SaveBehaviorTreatmentModificationTypeDataProperties) => FetchAPI.postJson({
        urlStr: `${CLIENT_SOAP_NOTES(clientId)}/behavior-treatment-modification`,
        data,
    }),
    updateBehaviorTreatmentModification: ({ clientId, reportId, data }: UpdateBehaviorTreatmentModificationTypeDataProperties) =>
        FetchAPI.putJson({
            urlStr: `${CLIENT_SOAP_NOTES(clientId)}/behavior-treatment-modification/${reportId}`,
            data,
        }),
    //Assessment
    getAssessmentOptions: (clientId: string, sessionId: string) => {
        const query = new URLSearchParams();
        query.set("sessionId", sessionId);
        return FetchAPI.getJson({
            urlStr: `${CLIENT_SOAP_NOTES(clientId)}/assessment/options?${query.toString()}`,
        })
    },
    saveAssessment: ({ clientId, data }: SaveAssessmentTypeDataProperties) => FetchAPI.postJson({
        urlStr: `${CLIENT_SOAP_NOTES(clientId)}/assessment`,
        data,
    }),
    updateAssessment: ({ clientId, reportId, data }: UpdateAssessmentTypeDataProperties) =>
        FetchAPI.putJson({
            urlStr: `${CLIENT_SOAP_NOTES(clientId)}/assessment/${reportId}`,
            data,
        }),
    //Parent Training
    getParentTrainingOptions: (clientId: string, sessionId: string) => {
        const query = new URLSearchParams();
        query.set("sessionId", sessionId);

        return FetchAPI.getJson({
            urlStr: `${CLIENT_SOAP_NOTES(clientId)}/parent-training/options?${query.toString()}`,
        })
    },
    saveParentTraining: ({ clientId, data }: SaveParentTrainingTypeDataProperties) => FetchAPI.postJson({
        urlStr: `${CLIENT_SOAP_NOTES(clientId)}/parent-training`,
        data,
    }),
    updateParentTraining: ({ clientId, reportId, data }: UpdateParentTrainingTypeDataProperties) =>
        FetchAPI.putJson({
            urlStr: `${CLIENT_SOAP_NOTES(clientId)}/parent-training/${reportId}`,
            data,
        }),
    //Treatment Planning
    getTreatmentPlanningOptions: (clientId: string, sessionId: string) => {
        const query = new URLSearchParams();
        query.set("sessionId", sessionId);
        return FetchAPI.getJson({
            urlStr: `${CLIENT_SOAP_NOTES(clientId)}/treatment-planning/options?${query.toString()}`,
        })
    },
    saveTreatmentPlanning: ({ clientId, data }: SaveTreatmentPlanningTypeDataProperties) => FetchAPI.postJson({
        urlStr: `${CLIENT_SOAP_NOTES(clientId)}/treatment-planning`,
        data,
    }),
    updateTreatmentPlanning: ({ clientId, reportId, data }: UpdateTreatmentPlanningTypeDataProperties) =>
        FetchAPI.putJson({
            urlStr: `${CLIENT_SOAP_NOTES(clientId)}/treatment-planning/${reportId}`,
            data,
        }),
}