import { SaveDirectSupervisionProperties } from "../SoapNoteProperties/directSupervisionTypeProperties";

export enum ACTIONS {
    setState = "setState",
    setSupervisedSessionId = "setSupervisedSessionId",
    setPromtedEffectively = "setPromtedEffectively",
    setReinforcerImplementation = "setReinforcerImplementation",
    setDataEnteredCorrectly = "setDataEnteredCorrectly",
    setFeedbackResponses = "setFeedbackResponses",
    setImplementationOfGoals = "setImplementationOfGoals",
    setResponseToBehavioralFeedback = "setResponseToBehavioralFeedback",
    setClientPerformance = "setClientPerformance",
    setChangesAndMeasurementsObserved = "setChangesAndMeasurementsObserved",
    setDescriptionOfSession = "setDescriptionOfSession",
    setSignature = "setSignature",
    setPrintedName = "setPrintedName",
    setLocationId = "setLocationId",
    setClientTelehealthLocationId = "setClientTelehealthLocationId",
    setUserTelehealthLocationId = "setUserTelehealthLocationId",
    setParticipants = "setParticipants",
}

export interface ActionProperties {
    type: keyof typeof ACTIONS,
    payload: any,
};

export const initialDirectSupervisionState: SaveDirectSupervisionProperties = {
    behaviorTechName: '',
    changesAndMeasurementsObserved: '',
    clientPerformance: "",
    dataEnteredCorrectly: false,
    date: "",
    descriptionOfSession: "",
    endTime: "",
    feedbackResponses: [],
    implementationOfGoal: '',
    isReinforcerImplementationRight: false,
    locationId: "",
    printedName: "",
    promptedEffectively: false,
    responseToBehavioralFeedback: "",
    sessionId: "",
    signature: "",
    startTime: "",
    submit: false,
    supervisedSessionId: null,
    supervisorId: "",
    forcedOperation: false,
    clientTelehealthLocationId: '',
    userTelehealthLocationId: '',
    participantResponses: [],
}

export const directSuperVisionReducer = (state: SaveDirectSupervisionProperties, action: ActionProperties): SaveDirectSupervisionProperties => {
    const { type, payload } = action;

    switch (type) {
        case ACTIONS.setState:
            return {
                ...state,
                ...payload
            }
        case ACTIONS.setSupervisedSessionId:
            return {
                ...state,
                supervisedSessionId: payload,
            }
        case ACTIONS.setPromtedEffectively:
            return {
                ...state,
                promptedEffectively: payload,
            }
        case ACTIONS.setReinforcerImplementation:
            return {
                ...state,
                isReinforcerImplementationRight: payload,
            }
        case ACTIONS.setDataEnteredCorrectly:
            return {
                ...state,
                dataEnteredCorrectly: payload,
            }
        case ACTIONS.setFeedbackResponses:
            return {
                ...state,
                feedbackResponses: payload,
            }
        case ACTIONS.setParticipants:
            return {
                ...state,
                participantResponses: payload,
            }
        case ACTIONS.setImplementationOfGoals:
            return {
                ...state,
                implementationOfGoal: payload,
            }
        case ACTIONS.setResponseToBehavioralFeedback:
            return {
                ...state,
                responseToBehavioralFeedback: payload,
            }
        case ACTIONS.setClientPerformance:
            return {
                ...state,
                clientPerformance: payload,
            }
        case ACTIONS.setChangesAndMeasurementsObserved:
            return {
                ...state,
                changesAndMeasurementsObserved: payload,
            }
        case ACTIONS.setDescriptionOfSession:
            return {
                ...state,
                descriptionOfSession: payload,
            }
        case ACTIONS.setSignature:
            return {
                ...state,
                signature: payload,
            }
        case ACTIONS.setLocationId:
            return {
                ...state,
                locationId: payload,
            }
        case ACTIONS.setPrintedName:
            return {
                ...state,
                printedName: payload,
            }
        case ACTIONS.setClientTelehealthLocationId:
            return {
                ...state,
                clientTelehealthLocationId: payload,
            }
        case ACTIONS.setUserTelehealthLocationId:
            return {
                ...state,
                userTelehealthLocationId: payload,
            }
        default:
            return state
    }
}

export enum ERRORS {
    SupervisedSessionId = "SupervisedSessionId",
    PromtedEffectively = "PromtedEffectively",
    ReinforcerImplementation = "ReinforcerImplementation",
    DataEnteredCorrectly = "DataEnteredCorrectly",
    FeedbackResponses = "FeedbackResponses",
    ImplementationOfGoal = "ImplementationOfGoal",
    ResponseToBehavioralFeedback = "ResponseToBehavioralFeedback",
    ClientPerformance = "ClientPerformance",
    ChangesAndMeasurementsObserved = "ChangesAndMeasurementsObserved",
    DescriptionOfSession = "DescriptionOfSession",
    Signature = "Signature",
    LocationId = "LocationId",
    ParticipantResponses = "ParticipantResponses",
}

interface DirectSupervisionErrorStateProperties {
    SupervisedSessionId: boolean,
    PromtedEffectively: boolean,
    ReinforcerImplementation: boolean,
    DataEnteredCorrectly: boolean,
    FeedbackResponses: boolean,
    ImplementationOfGoal: boolean,
    ResponseToBehavioralFeedback: boolean,
    ClientPerformance: boolean,
    ChangesAndMeasurementsObserved: boolean,
    DescriptionOfSession: boolean,
    Signature: boolean,
    LocationId: boolean,
    ParticipantResponses: boolean,
}

interface ErrorActionProperties {
    type: keyof typeof ERRORS,
    payload: any,
}

export const initialDirectSupervisionErrorState: DirectSupervisionErrorStateProperties = {
    SupervisedSessionId: false,
    PromtedEffectively: false,
    ReinforcerImplementation: false,
    DataEnteredCorrectly: false,
    FeedbackResponses: false,
    ImplementationOfGoal: false,
    ResponseToBehavioralFeedback: false,
    ClientPerformance: false,
    ChangesAndMeasurementsObserved: false,
    DescriptionOfSession: false,
    Signature: false,
    LocationId: false,
    ParticipantResponses: false,
}

export const directSuperVisionErrorReducer = (state: DirectSupervisionErrorStateProperties, action: ErrorActionProperties) => {
    const { payload, type } = action;
    switch (type) {
        case ERRORS[type]:
            return {
                ...state,
                [type]: payload,
            }
        default:
            return state
    }
}